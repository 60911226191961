<template>
  <ul>
    <b-nav-item-dropdown
      id="dropdown-grouped"
      link-classes="bookmark-star"
      lazy
      variant="link"
      class="list-itens-sites"
    >
      <template #button-content>
        <IconDatabases />
        <span>Sites</span>
        <IconArrowDown class="arrow-icon" />
      </template>

      <b-dropdown-item
        v-for="site in sites"
        :key="site.guid"
        class="item-site-vertical"
        :style="getItemColor(site, 'item')"
        @click="setSite(site)"
      >
        <span :style="getItemColor(site, 'item')">{{ site.name }}</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>

    <li class="nav-item mode">
      <b-link class="d-flex align-items-center" @click="toggleOperatorMode">
        <IconPCP v-if="isNavMenuHidden" class="icon-pcp-mobile" />
        <feather-icon v-else size="16" icon="GridIcon" />
        <span>{{
          isNavMenuHidden ? $t("PCP Mode") : $t("Operator Mode")
        }}</span>
      </b-link>
    </li>

    <li class="nav-item" @click="redirectToChangeAccount">
      <b-link class="d-flex align-items-center">
        <IconReload />
        <span>{{ $t("ChangeAccount") }}</span>
      </b-link>
    </li>

    <div class="line" />

    <template v-if="this.$route.name.split('-')[0] != 'operator'">
      <component
        :is="resolveNavItemComponent(item)"
        v-for="item in items"
        :key="item.header || item.title"
        :item="item"
      />
    </template>

    <li v-if="$route.name.split('-')[0] === 'operator'" class="nav-item">
      <b-link
        class="d-flex align-items-center"
        :to="{ name: 'operator-orders-all' }"
      >
        <OrdersButtonIcon />
        <span>{{ $t("Operator.Navigation.Orders.Title") }}</span>
      </b-link>
    </li>
    <li v-if="$route.name.split('-')[0] === 'operator'" class="nav-item">
      <b-link
        class="d-flex align-items-center"
        :to="{ name: 'operator-barcode' }"
      >
        <BarCodeButtonIcon />
        <span>{{ $t("Operator.Navigation.BarCode.Title") }}</span>
      </b-link>
    </li>
    <li v-if="$route.name.split('-')[0] !== 'operator'" class="nav-item">
      <b-link
        class="d-flex align-items-center"
        :to="{ name: 'account-setting' }"
      >
        <IconProfile />
        <span>{{ $t("Profile") }}</span>
      </b-link>
    </li>

    <li v-if="$route.name.split('-')[0] !== 'operator'" class="nav-item">
      <b-link class="d-flex align-items-center" :to="{ name: 'support' }">
        <IconSupport />
        <span>{{ $t("Support") }}</span>
      </b-link>
    </li>

    <Locale />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      :class="$route.name.split('-')[0] === 'operator' && 'item-logout'"
      @click="redirectToChangeAccount"
    >
      <IconLogout />
      <span>{{ $t("Logout") }}</span>
    </b-dropdown-item>
  </ul>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import { initialAbility } from "@/libs/acl/config";
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import { BLink, BNavItemDropdown, BDropdownItem } from "bootstrap-vue";
import useAppConfig from "@core/app-config/useAppConfig";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import SiteSelect from "@core/layouts/components/app-navbar/components/SiteSelect.vue";
import IconProfile from "@/assets/images/pages/profile-icon.svg";
import IconSupport from "@/assets/images/pages/support-icon.svg";
import IconArrowDown from "@/assets/images/pages/arrow-down-icon.svg";
import IconDatabases from "@/assets/images/pages/database-icon.svg";
import IconLogout from "@/assets/images/pages/logout-icon.svg";
import IconPCP from "@/assets/images/icons/modo-pcp.svg";
import IconReload from "@/assets/images/pages/reload-icon.svg";
import router from "@/router";
import OrdersButtonIcon from "@/assets/images/pages/orders-button-icon.svg";
import BarCodeButtonIcon from "@/assets/images/pages/barcode-button-icon.svg";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";

export default {
  components: {
    OrdersButtonIcon,
    BarCodeButtonIcon,
    BNavItemDropdown,
    BDropdownItem,
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    BLink,
    Locale,
    SiteSelect,
    IconProfile,
    IconSupport,
    IconArrowDown,
    IconDatabases,
    IconLogout,
    IconReload,
    IconPCP,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      prefix:
        localStorage.getItem("prefix") ||
        this.$router.currentRoute.params.prefix,
      sites: null,
    };
  },
  setup() {
    provide("openGroups", ref([]));
    const { isNavMenuHidden } = useAppConfig();

    return {
      resolveNavItemComponent,
      isNavMenuHidden,
    };
  },
  created() {
    this.getSites();
  },
  methods: {
    getItemColor(site, type) {
      const currentSite = this.$cookies.get('userInfo').currentSite;
      if (currentSite == site.guid) {
        if (type == "item") {
          return "background-color: #974900; color: #ffff !important; border-radius: 6px !important;";
        }
        if (type == "icon") {
          return "color:#ffff";
        }
        return "color: #ffff !important";
      }
      return "background-color: transparent ; color: #998F8A";
    },
    logout() {
      useJwt
        .logout(this.$router.currentRoute.params.prefix)
        .then((response) => {
          localStorage.clear();

          // Reset ability
          this.$ability.update(initialAbility);

          // Delete cookie
          const domain = window.location.href.includes("indsiders.com");
          this.$cookies.remove(
            "userInfo",
            null,
            domain ? "indsiders.com" : null
          );
          // Redirect to login page
          window.location.href = `${this.$myIndLink}login`;
        })
        .catch((error) => {});
    },
    getSites() {
      this.sites = JSON.parse(localStorage.getItem("sites"));
    },
    setSite(site) {
      localStorage.setItem("currentSite", JSON.stringify(site));
      this.$router.go();
    },
    toggleOperatorMode() {
      // this.isNavMenuHidden = !this.isNavMenuHidden
      return this.$route.name.split("-")[0] == "operator"
        ? this.$router.push({ name: "home", params: { prefix: this.prefix } })
        : this.$router.push({
            name: "operator",
            params: { prefix: this.prefix },
          });
    },
    redirectToChangeAccount() {
      router.push({ name: "change-account" });
    },
  },
};
</script>
<style lang="scss">
.item-logout {
  position: absolute !important;
  bottom: 8px !important;
  width: auto;
  a:hover,
  a:active {
    background-color: transparent !important;
    background: transparent !important;
  }
}
.line {
  width: 100%;
  height: 1px;
  background-color: #cfc4be;
  margin: 12px 0;
}
.main-menu {
  width: 260px !important;
}
.main-menu .navigation .list-itens-sites.show .nav-link {
  background: #ffdbc4 !important;
  svg {
    path {
      stroke: #974900 !important;
    }
  }
  span {
    color: #974900;
  }
  .arrow-icon {
    top: 16px !important;
    margin-right: 25px;
  }
}
.list-itens-sites {
  .nav-link {
    position: relative !important;
    .arrow-icon {
      position: absolute !important;
      transform: rotate(270deg);
      float: none !important;
      margin-top: 0 !important;
      right: -18px;
      top: 8px !important;
    }
  }
}
.list-itens-sites,
.nav-locale {
  a {
    position: relative !important;
    .arrow-icon {
      position: absolute !important;
      transform: rotate(270deg);
      float: none !important;
      margin-top: 0 !important;
      right: -18px;
      top: 8px !important;
    }
  }
  .dropdown-menu {
    position: relative !important;
    margin: 0 !important;
    box-shadow: none;
    border: none;
    width: 88%;
    left: 17px !important;
    li {
      margin: 0 !important;
      margin-top: 0 !important;
      .dropdown-item {
        background: transparent !important;
        padding-left: 45px !important;
        margin-bottom: 4px;
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #998f8a;
        }
      }
    }
  }
}

.icon-pcp-mobile {
  stroke: none !important;
  path {
    fill: #998f8a !important;
  }
}
</style>
